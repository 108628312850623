@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spline+Sans:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spline+Sans:wght@300..700&display=swap');
html {
    scroll-behavior: smooth;
  }
  body{
    margin: 0;
    padding: 0;
    background: #FFFEF5 !important;
}
.new_ui_all{
    overflow: hidden !important;
}
body::-webkit-scrollbar {
    width: 5px;  
  }
  
  body::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #47463e 40%, #917f7f 100%);
    border-radius: 10px;
  }
  
  body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px; 
  }
.banner_img{
    background-image: url('../Pages/Newimages/blackbanner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.new_ban_des h2{
    font-family: "Open Sans";
font-size: 68px;
font-style: normal;
font-weight: 700;
line-height: 88px; /* 129.412% */
background: linear-gradient(92deg, #FFF 56.63%, #FCE14A 83.81%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;

}
.ban_span {
    color: #FFDD1D !important;
font-family: "Open Sans";
font-size: 68px;
font-style: normal;
font-weight: 700;
line-height: 88px;
}
.new_ban_des p{

    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 160% */
padding-bottom: 40px;
}
.new_ban_des button{
    color: #000;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 8px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
display: flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 2px;
border: none;
}

.trust_companies{
    background: #262626;
    padding-top: 42px;
    padding-bottom: 42px;
}
.trust_companies h2{
    font-family: "Open Sans";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 40px; /* 125% */
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
padding-bottom: 20px;
}
.com_img_all{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
}

.trust_below_des p{
    font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 34px; /* 170% */
background: linear-gradient(281deg, #1A1A1A 67.82%, #FFD04E 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}
.trust_below_des h2{
    font-family: "Spline Sans";
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: 56px; /* 133.333% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 80.34%, #FFD04E 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}


.i-card-des h2{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom: 16px;
  }
  .i-card-des p{
    color: #525252;
    font-family: "Spline Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }

  .i-card{
    display: flex;
    align-items: center;
    gap: 40px;
    border-radius: 12px;
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
padding: 20px 20px 20px 40px;
margin-bottom: 12px;
  }
  .num span{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .transactions{
    display: flex;
    align-items: center;
    gap: 80px;
  }
  .half-i-des h2{
    font-family: "Spline Sans";
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: 56px; /* 133.333% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 80.34%, #FFD04E 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;
  }
  .transaction-col h3{
    color: #1A1A1A;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 111.111% */
padding-bottom: 8px;
padding-top: 16px;

  }
  .transaction-col p{
    color: #525252;
font-family: "Spline Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 166.667% */

  }
  .lap-sec{
    border-radius: 32px 32px 0px 0px;
background: linear-gradient(167deg, #181818 24.94%, #1B1B1B 50.77%, #181818 76.33%);
  }
  .lap-sec h1{
    text-align: center;
font-family: "Open Sans";
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 142.857% */
background: linear-gradient(92deg, #FCE14A 1.44%, #FFF 39.66%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
padding-top: 65px;
  }

  .peoples{
    display: flex;
    align-items: center;
    gap: 60px;
    padding-top: 35px;
  }
  .peoples h3{
    color: #FFF;
text-align: center;
font-family: "Spline Sans";
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 66.667% */
padding-bottom: 32px;
  }
  .peoples h2{
    color: #FFF;
-webkit-text-stroke-width: 0.20000000298023224;
-webkit-text-stroke-color: #FFF;
font-family: "Spline Sans";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 90.909% */
text-align: center;
padding-bottom: 10px;
  }
  .peoples p{
    color: #E3E1D8;
    text-align: center;
    font-family: "Spline Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 141.176% */
    
  }
  .mobile-shade{
    border-radius: 40px;
background: #262626;
height: 360px;
  }
  .app-imgg{
    position: relative;
    bottom: 70px;
  }
  .soon-all{
    padding-left: 80px;
    padding-top: 40px;
  }
 
.soon-all p{
    font-family: "Open Sans";
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 142.857% */
background: linear-gradient(91deg, #FFF 47.84%, #FCE14A 98.54%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;
}
.soon{
    display: flex;
    align-items: center;
    gap: 26px;
}
.lap-img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 37px;
  }



  /* Navigation Container */
.navmain_container {
    position: relative;
  }
  
  /* Burger Icon */
  .burger_icon {
    display: none;
    cursor: pointer;
    color: white !important;
  }
  
  /* Mobile Menu */
  .mobile_menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 250px;
    height: 100vh;
    background-color: #141313;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    z-index: 1000;
  }
  
  .mobile_menu.open {
    left: 0;
  }
  
  .mobile_menu .close_btn {
    text-align: right;
    padding: 10px;
    cursor: pointer;
  }
  
  .mobile_menu ul {
    list-style: none;
    padding: 20px;
    margin: 0;
  }
  
  .mobile_menu ul li {
    margin-bottom: 15px;
    color: #fff;
  }
  
  .mobile_menu ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
  }
  
  /* Default Styles for Desktop */
  .nav_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Hide Mobile Menu and Show Burger Icon on Mobile */
  @media (max-width: 768px) {
    .burger_icon {
      display: block;
    }
  .navmain_container{
padding-left: 10px !important;  
}
  
    .nav_list_items,
    .Login_btn {
      display: none !important;
    }
  
  }
  

  .Login_btn{
    display: flex;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 8px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
  }
  .Login_btn a{
    color: #000;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-decoration: none;
  }

  .navmain_container{
    background: #202020;
    padding: 24px;
    padding-left: 60px;
  }

  .nav_list_items ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav_list_items ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  
  .nav_list_items ul li a:hover {
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .logo_container{
    display: flex;
    align-items: center;
    gap: 60px;
    flex-direction: row;
  }


.footer_all{
    border: 1px solid rgba(255, 255, 255, 0.20);
/* background-color: #FFFFFF1A !important; */
background-color: #201b1b;
border: grey 1px solid;


}

.footer{
    padding-top: 80px;
padding-bottom: 80px;
}

.foot_svgs{
    display: flex;
    align-items: center;
    gap: 16px;
}
.quick_link_all h2{
    color: #FFF;
font-family: "Spline Sans";
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 29px; /* 131.818% */
padding-bottom: 24px;
}
.quick_link_all li{
    padding-bottom: 12px;
    margin: 0;
}
.quick_link_all ul{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
padding: 0;
margin: 0;
list-style: none;
}

.address_sec p{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
padding-top: 24px;
padding-bottom: 16px;

}
.address_sec a{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 182.353% */
padding-bottom: 16px;
text-decoration: none;
}

.footer_reserve_con p{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 221.429% */
margin: 0;
text-align: center;
}
.footer_reserve_con{
    border: 1px solid rgba(255, 255, 255, 0.20);
background: rgba(255, 255, 255, 0.05);
padding: 20px;
border: none;
}


/* end of Navbar,Homepage and Footer  */


/* About Us */

.new_about_banner_img{
    background-image: url('./Newimages//blackbanner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.new_about_des{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 900px !important;
padding-top: 20px;
padding-bottom: 160px;
}

.new_about_des h2{
    text-align: center;
font-family: "Open Sans";
font-size: 68px;
font-style: normal;
font-weight: 700;
line-height: 88px; /* 129.412% */
background: linear-gradient(92deg, #FFF 56.63%, #FCE14A 83.81%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}
.new_about_des p{
    color: #E3E1D8;
text-align: center;
font-family: "Spline Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 34px; /* 170% */
padding-bottom: 40px;
padding-top: 32px;
margin: 0;

}
.new_about_des button{
    border: none;
    color: #000;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 8px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
}

.mission_story_des{
    border-radius: 60px;
    border: 1px solid #EFE0BB;
    background: #FFFEF5;
    box-shadow: 0px 0px 50px 0px rgba(254, 217, 94, 0.05);
    padding: 60px !important;
    position: relative;
    bottom: 100px;

}
.mission_des{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.mission_des h2{
    font-family: "Spline Sans";
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: 56px; /* 133.333% */
opacity: 0.9;
background: linear-gradient(280deg, #1A1A1A 60.45%, #FFD04E 97.07%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 24px;
}
.mission_des p{
    color: #525252;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 177.778% */
}

.story_des h2{
    font-family: "Spline Sans";
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: 56px; /* 133.333% */
opacity: 0.9;
background: linear-gradient(280deg, #1A1A1A 60.45%, #FFD04E 97.07%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 40px;
padding-bottom: 23px;

}
.story_des p{
    color: #525252;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 177.778% */
}

.mission_des_all{
    padding-bottom: 120px;
}
.story_img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.about_manage_all{
    border-radius: 32px 32px 0px 0px;
background: linear-gradient(167deg, #181818 24.94%, #1B1B1B 50.77%, #181818 76.33%);

}
.manage_img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.about_manage_all h2{
    text-align: center;
font-family: "Open Sans";
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 142.857% */
background: linear-gradient(92deg, #FCE14A 1.44%, #FFF 39.66%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 60px;
padding-bottom: 71px;
}



/* contact Us css */

.contact_banner_all{
    background-image: url('./Newimages/blackbanner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.new_about_ban_des{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 140px;
    padding-bottom: 120px;
}
.new_about_ban_des h2{
    text-align: center;
font-family: "Open Sans";
font-size: 68px;
font-style: normal;
font-weight: 700;
line-height: 88px; /* 129.412% */
background: linear-gradient(92deg, #FFF 56.63%, #FCE14A 83.81%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;
width: 860px;

}
.new_about_ban_des p{
    width: 860px;

    color: #E3E1D8;
text-align: center;
font-family: "Spline Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 34px; /* 170% */
}
.new_get_in_touch button{
    border-radius: 8px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
display: flex;
height: 60px;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 2px;
align-self: stretch;
border: none;
width: 100%;
}
.new_get_in_touch input{
    border-radius: 8px;
background: #F6F4EC;
display: flex;
height: 60px;
padding: 20px;
align-items: center;
gap: 10px;
align-self: stretch;
margin-bottom: 16px;
/* width: 100%; */
}
.new_con_form{
padding: 40px;
    display: flex;
flex-direction: column;
border-radius: 24px;
border: 1px solid #EFE0BB;
background: #FFF;
box-shadow: 0px 0px 50px 0px rgba(254, 217, 94, 0.05);
}
.new_con_form h1{
    font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 105% */
opacity: 0.9;
background: linear-gradient(280deg, #1A1A1A 60.45%, #FFD04E 97.07%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;

}
.new_con_form p{
    color: #525252;
text-align: center;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 177.778% */
padding-bottom: 32px;
padding-top: 32px;  
}

@media (max-width: 768px) {


    .new_ban_des h2{
        font-family: "Open Sans";
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 150% */
padding-bottom: 24px;
    }
    .new_ban_des p{
        color: #E3E1D8;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
padding-bottom: 24px;
margin: 0;
    }

    .new_ban_des button{
        color: #000;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 6.154px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
    }
    .trust_companies h2{
        text-align: center;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 100% */
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .trust_below_des p {
        font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 175% */
background: linear-gradient(281deg, #1A1A1A 67.82%, #FFD04E 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .trust_below_des h2{
        font-family: "Spline Sans";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 46px; /* 143.75% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 80.34%, #FFD04E 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .i-card-des h2{
        color: #1A1A1A;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
    }
    .i-card-des p{
        color: #525252;
font-family: "Spline Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 176.923% */
    }
    .i-card{
        display: flex;
padding: 16px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    }
    .num span{
        color: #1A1A1A;
font-family: "Spline Sans";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .half-i-des h2{
        padding-top: 25px;
        text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 69.73%, #FFD04E 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .transactions{
        display: block;
    }
    .transaction-col h3{
        color: #1A1A1A;
font-family: "Spline Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 100% */
    }
    .transaction-col p{
        color: #525252;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
    }
    .lap-sec h1{
        text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
background: linear-gradient(92deg, #FCE14A 1.44%, #FFF 39.66%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .peoples h3{
        color: #FFF;
text-align: center;
font-family: "Spline Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 100% */
    }
    .peoples h2{
        color: #FFF;
-webkit-text-stroke-width: 0.20000000298023224;
-webkit-text-stroke-color: #FFF;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 111.111% */
    }
    .peoples p{
        color: #E3E1D8;
text-align: center;
font-family: "Spline Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 176.923% */
    }
    .mobile-shade{
        height: 100%;
    }
    .soon-all p{
        text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
background: linear-gradient(92deg, #FCE14A 1.44%, #FFF 39.66%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .soon-all{
        padding-left: 0;
    }
    .app-imgg{
        bottom: 0;
    }
    .soon{
        display: block;
        padding-left: 40px;
    }
    .new_about_ban_des h2{
        text-align: center;
font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 40px; /* 153.846% */
background: linear-gradient(92deg, #FFF 56.63%, #FCE14A 83.81%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
width: 100%;
padding-bottom: 22px;

    }
    .new_about_ban_des p{
        color: #E3E1D8;
text-align: center;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
width: 100%;
    }
    .new_about_ban_des{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .new_con_form h1{
        font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 24px */
opacity: 0.9;
background: linear-gradient(280deg, #1A1A1A 60.45%, #FFD04E 97.07%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .new_con_form p{
        color: #525252;
text-align: center;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
padding-top: 16px;
padding-bottom: 23px;
margin: 0;
    }
    .new_get_in_touch input{
        display: flex;
/* width: 325px; */
height: 48px;
padding: 16px;
align-items: center;
gap: 5.907px;
border-radius: 6px;
background: #F6F4EC;
    }
    .new_get_in_touch button{
        display: flex;
/* width: 325px; */
height: 48px;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 2px;
border-radius: 8px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
color: #000;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 14px; /* 100% */
    }
    .new_con_form{
        padding: 0px;
        display: flex;
        flex-direction: column;
        border-radius: none;
        border:none;
        background: none;
        box-shadow: none;
    
    }
    .new_about_des h2{
        text-align: center;
font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 40px; /* 153.846% */
background: linear-gradient(92deg, #FFF 56.63%, #FCE14A 83.81%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .new_about_des p{
        color: #E3E1D8;
text-align: center;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
    }
    .new_about_des {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100% !important;
        padding-top: 20px;
        padding-bottom: 70px;
    }
    .new_about_des button{
        color: #000;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 6.154px;
background: linear-gradient(273deg, #FFC039 -7.66%, #FFD95E 79.62%);
    }
     .mission_story_des {
    border-radius: 0;
    border: none;
    background: none;
    box-shadow: none;
    padding: 40px !important;
    position: static;
    bottom: unset;
  }
  .mission_des h2{
    font-family: "Spline Sans";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 24px */
opacity: 0.9;
background: linear-gradient(280deg, #1A1A1A 60.45%, #FFD04E 97.07%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
  }
  .mission_des p{
    color: #525252;
text-align: center;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
  }
  .mission_des_all {
    padding-bottom: 40px;
}
.story_des h2{
    font-family: "Spline Sans";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 24px */
opacity: 0.9;
background: linear-gradient(280deg, #1A1A1A 60.45%, #FFD04E 97.07%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
}
.story_des p{
    color: #525252;
text-align: center;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 185.714% */
}
.about_manage_all h2{
    text-align: center;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
background: linear-gradient(92deg, #FCE14A 1.44%, #FFF 39.66%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 40px;
padding-bottom: 40px;
}
.address_sec p, .address_sec a{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}
.quick_link_all h2{
    color: #FFF;
font-family: "Spline Sans";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 100% */
padding-top: 43px;
}
.com_img_all img {
    height: 20px !important;
}
.quick_link_all ul{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}
.footer_reserve_con p{
    color: #E3E1D8;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 31px; /* 221.429% */
}
.quick_link_all li{
    padding-bottom: 6px;
}
.com_img_all{
    /* overflow: auto !important; */
    gap: 40px;
}
.marquee {
    /* display: flex; */
    animation: marquee 20s linear infinite !important;
  }
}
  
.com_img_all {
    display: flex;
    /* overflow: hidden; */
    white-space: nowrap;
  }
  
  .com_img_all img {
    flex-shrink: 0;
    width: auto;
    height: auto; /* Adjust based on your design */
    margin-right: 20px; /* Spacing between images */
  }
  
  .marquee {
    display: flex;
    animation: marquee 30s linear infinite;
  }
  
  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .quick_link_all a{
    text-decoration: none;
    color: white;
  }